// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createAction, createAsyncAction } from 'typesafe-actions';
import ErrorImpl from '../../service/errorImpl';
import { AdminAuthParams, GetAADLinkParams, StartSetupParams } from './types';

export const setSessionId = createAction(
  'initial-setup/SET_SESSION_ID',
)<string>();
export const clearSessionId = createAction(
  'initial-setup/CLEAR_SESSION_ID',
)<void>();
export const invalidateSession = createAction(
  'initial-setup/INVALIDATE_SESSION',
)<void>();
export const sessionExpired = createAction(
  'initial-setup/SESSION_EXPIRED',
)<void>();
export const linkDeadSetupAlreadyComplete = createAction(
  'initial-setup/LINK_DEAD',
)<void>();
export const sessionTimeout = createAction(
  'initial-setup/SESSION_TIMEOUT',
)<void>();

export const getConsentUrl = createAsyncAction(
  'initial-setup/GET_AAD_LINK_REQUEST',
  'initial-setup/GET_AAD_LINK_SUCCESS',
  'initial-setup/GET_AAD_LINK_ERROR',
  'initial-setup/GET_AAD_LINK_CANCEL',
)<GetAADLinkParams, string, ErrorImpl, string>();
export const clearAADLinkError = createAction(
  'initial-setup/CLEAR_AAD_ERROR',
)<void>();

export const completeSetup = createAsyncAction(
  'initial-setup/ADMIN_AUTH_REQUEST',
  'initial-setup/ADMIN_AUTH_SUCCESS',
  'initial-setup/ADMIN_AUTH_ERROR',
)<AdminAuthParams, void, ErrorImpl>();
export const clearAdminAuthError = createAction(
  'initial-setup/CLEAR_ADMIN_AUTH_ERROR',
)<void>();

export const findDirectories = createAsyncAction(
  'initial-setup/FIND_DIRECTORIES_REQUEST',
  'initial-setup/FIND_DIRECTORIES_SUCCESS',
  'initial-setup/FIND_DIRECTORIES_ERROR',
)<void, string, ErrorImpl>();

export const groupSyncStart = createAsyncAction(
  'initial-setup/GROUP_SYNC_START_REQUEST',
  'initial-setup/GROUP_SYNC_START_SUCCESS',
  'initial-setup/GROUP_SYNC_START_ERROR',
)<string, void, ErrorImpl>();

export const startSetup = createAsyncAction(
  'initial-setup/START_SETUP_REQUEST',
  'initial-setup/START_SETUP_SUCCESS',
  'initial-setup/START_SETUP_ERROR',
  'initial-setup/START_SETUP_CANCEL',
)<StartSetupParams, string, ErrorImpl, string>();
export const clearStartSetupError = createAction(
  'initial-setup/CLEAR_START_SETUP_ERROR',
)<void>();
