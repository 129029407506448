// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useCallback, useEffect, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ApiError } from '../service/types';
import {
  completeSetup,
  clearAdminAuthError,
} from '../store/initialSetup/actions';
import {
  adminAuthErrorSelector,
  adminAuthPendingSelector,
} from '../store/initialSetup/selectors';
import { AdminAuthParams } from '../store/initialSetup/types';
import storage from '../utils/storage';
import { adminUsernameField, StorageKeys } from '../constants/fieldNames';
import {
  Banner,
  destroyBanner,
} from '@imprivata-cloud/astra-ui-component-library';
import { BANNER_DURATION } from '../constants/misc';

export const useAdminCredsRequest = (): {
  pending: boolean;
  error: ApiError | null;
  onSubmit(values: AdminAuthParams): void;
} => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pending = useSelector(adminAuthPendingSelector);
  const error = useSelector(adminAuthErrorSelector);
  const onSubmit = useCallback(
    (values: AdminAuthParams) => {
      if (!validateEmail(values[adminUsernameField])) {
        showInvalidEmailBanner(t);
        return;
      }
      values[StorageKeys.CODING_CTX] = storage.getItem(StorageKeys.CODING_CTX);
      dispatch(completeSetup.request(values));
    },
    [dispatch, t],
  );

  useEffect(() => {
    const clearError = () => {
      dispatch(clearAdminAuthError());
    };
    const destroy = () => destroyBanner();

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        destroy();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    if (error) {
      Banner({
        type: 'warning',
        message: t('messages.errors.auth-fail-primary'),
        description: t('messages.errors.auth-fail-secondary'),
        onClose: clearError,
        onClick: clearError,
        duration: BANNER_DURATION,
        datatestid: 'admin-auth-error',
      });
    } else {
      destroy();
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      destroy();
    };
  }, [error, dispatch, t]);

  return useMemo(
    () => ({
      pending,
      error,
      onSubmit,
    }),
    [pending, error, onSubmit],
  );
};

function showInvalidEmailBanner(t: TFunction) {
  Banner({
    type: 'error',
    message: t('messages.validation.types.email'),
    description: t('messages.errors.try-again'),
    duration: BANNER_DURATION,
    datatestid: 'banner-invalid-email-format',
  });
}

// Simplified check is enough in our case
function validateEmail(email: string): boolean {
  return /\S+@\S+\.\S+/.test(email);
}
