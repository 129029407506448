// Copyright 2024, Imprivata, Inc.  All rights reserved.

interface AppEnvConfig {
  // Required variables
  API_URL: string;
  ADMIN_UI_URL: string;
  // Optional variables
  HTTPS?: boolean;
  PUBLIC_URL?: string;
  LOCAL_TRACING_URL?: string;
}

export const MOCKSERVER_DEFAULT_URL = 'http://localhost:1080';

export interface URLConfig {
  apiUrl?: string;
  ussUrl?: string;
  tracingUrl?: string;
  metadataUrl?: string;
  adminUrl?: string;
}

export function _getUrls(): URLConfig {
  const url = window.location.host;
  const urlRegex = /\w+\.\w+\.(\w+)\.\w+\.(\w+)/gim;
  const matches = urlRegex.exec(url);

  let apiUrl = 'https://astra.sys.imprivata.com';
  let ussUrl = 'https://user.app.imprivata.com/login';
  let metadataUrl = 'https://metadata.app.imprivata.com';
  let tracingUrl = 'https://astra.sys.imprivata.com/v1/traces';
  let adminUrl = 'https://admin.app.imprivata.com/login';
  if (url?.includes('localhost')) {
    apiUrl = MOCKSERVER_DEFAULT_URL;
    metadataUrl = 'https://metadata.app.dev.imprivata.cloud';
  } else if (Array.isArray(matches) && matches.length > 0) {
    matches.shift();
    const [appEnv, tld] = matches;
    console.log(appEnv, tld);

    adminUrl = `https://admin.app.${appEnv}.imprivata.${tld}/login`;
    ussUrl = `https://user.app.${appEnv}.imprivata.${tld}/login`;
    metadataUrl = `https://metadata.app.${appEnv}.imprivata.${tld}`;
    apiUrl = `https://astra.sys.${appEnv}.imprivata.${tld}`;
    tracingUrl = `https://astra.sys.${appEnv}.imprivata.${tld}/v1/traces`;
  }
  return { apiUrl, ussUrl, tracingUrl, metadataUrl, adminUrl };
}

let envConfig: AppEnvConfig;

export function _getEnvVariable(
  window: Window,
  env: NodeJS.ProcessEnv,
  name: string,
  defaultValue?: unknown,
): unknown {
  const reactAppEnvName = `REACT_APP_${name}`;

  if (window._appConfig?.hasOwnProperty(name)) {
    return window._appConfig[name];
  }

  interface ProcessEnvWithIndex {
    [key: string]: string | undefined;
  }

  if (env.hasOwnProperty(reactAppEnvName) || env.hasOwnProperty(name)) {
    return (
      (env as unknown as ProcessEnvWithIndex)[reactAppEnvName] ||
      (env as unknown as ProcessEnvWithIndex)[name]
    );
  }

  return defaultValue;
}

export function _initAppConfig(
  window: Window,
  env: NodeJS.ProcessEnv,
): AppEnvConfig {
  const { apiUrl, adminUrl } = _getUrls();
  // file deepcode ignore MissingArgument
  return {
    API_URL:
      apiUrl || (_getEnvVariable(window, env, 'API_URL', apiUrl) as string),
    ADMIN_UI_URL:
      adminUrl ||
      (_getEnvVariable(window, env, 'ADMIN_UI_URL', '/users/') as string),
    PUBLIC_URL: _getEnvVariable(window, env, 'PUBLIC_URL', '') as string,
    LOCAL_TRACING_URL: _getEnvVariable(
      window,
      env,
      'LOCAL_TRACING_URL',
      '',
    ) as string,
    HTTPS: _getEnvVariable(
      window,
      env,
      'HTTPS',
      env.NODE_ENV === 'production',
    ) as boolean,
  };
}

export default function getConfig(): AppEnvConfig {
  if (!envConfig) {
    envConfig = _initAppConfig(window, process.env);
  }
  return envConfig;
}
